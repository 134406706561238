@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Open+Sans+Condensed:ital,wght@0,300;0,700;1,300&display=swap');

:root{
  --font-base: 'Manrope', sans-serif;
  --font-alt: 'Open Sans Condensed', sans-serif;

  --color-button: #9D9D9D;

  --color-white: #fff;
  --color-black: #0C0C0C;
  --color-gray2: #959595;
  --color-gray1: #DEDEDE;
  --color-bg-dark: #292929;
  --color-bar-dark: #323232;
  --color-bg-light: #f3f3f3;
  --color-bar-light: #fff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: var(--font-base);
}

a {
  color: unset;
  text-decoration: none;
  cursor: pointer;
}

button {
  appearance: none;
  border-radius: 0.2rem;
  box-sizing: border-box;
  display: block;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.25rem 1rem;
  border: 1px solid var(--color-bg-dark);
  transition: background-color 200ms ease-in-out;
}

body {
  background-color: var(--color-bg-dark);
}

.app {
  background-color: var(--color-bg-light);
}
