.app__landingpagelight {
  background-color: var(--color-bg-light);
  height: auto;
  display: flex;
  flex-direction: row;
  padding-bottom: 9rem;
  width: 100%;
}

.app__landingpage-picturelight {
  height: 360px;
  width: 45%;
}

.app__landingpage-picturelight img {
  border-radius: 50%;
  width: 350px;
  object-fit: cover;
  border: 1px solid var(--color-black);
}

.app__landingpage-picture_framelight {
  margin-top: 10rem;
}

.app__landingpage-contentlight {
  padding-top: 10rem;
  width: 55%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.app__landingpage-introductionlight {
  margin-left: 10rem;
  color: var(--color-bg-dark);
  font-size: 3.5rem;
  font-family: var(--font-base);
  font-weight: 600;
}

.app__landingpage-subtextlight {
  margin-left: 10rem;
  color: var(--color-bar-dark);
  font-size: 1.5rem;
  font-family: var(--font-base);
  width: 60%;
}

@media screen and (min-width: 1900px) {
  .app__landingpage-introductionlight {
    font-size: 4.5rem;
  }

  .app__landingpage-subtextlight {
    font-size: 2.5rem;
  }

  .app__landingpagelight {
    padding-bottom: 20rem;
    padding-top: 12rem;
    padding-left: 8rem;
  }

  .app__landingpage-picturelight img {
    width: 550px;
  }
}

@media screen and (max-width: 900px) {
  .app__landingpagelight {
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
  }

  .app__landingpage-contentlight {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .app__landingpage-introductionlight {
    margin-left: 0;
  }

  .app__landingpage-subtextlight {
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-left: 0;
  }

  .app__landingpage-picturelight {
    width: 60%;
    height: auto;
  }

  .app__landingpage-picturelight img {
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .app__landingpagelight {
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
  }

  .app__landingpage-contentlight {
    width: 85%;
    height: auto;
  }

  .app__landingpage-introductionlight {
    font-size: 2.8rem;
    margin-left: 0;
    display: flex;
    flex-wrap: wrap;
    overflow-wrap: break-word;
  }

  .app__landingpage-subtextlight {
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-left: 0;
    width: 90%;
  }

  .app__landingpage-picturelight {
    width: 60%;
    height: auto;
  }

  .app__landingpage-picturelight img {
    width: 100%;
  }

  .app__landingpage-picture_framelight {
    margin-top: 5rem;
  }
}