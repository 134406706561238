
.app__about-resume__icon {
  margin-left: 0.5rem;
}

@media screen and (min-width: 1900px) {
  .app__about-text {
    font-size: 2rem;
  }

  .app__about-resume__link {
    font-size: 1.75rem;
  }

  .app__about {
    padding-top: 7.5rem;
    padding-bottom: 17.5rem;
  }
}

@media screen and (max-width: 650px) {
  .app__about {
    padding-bottom: 8rem;
  }

  .app__about-text {
    width: 80%;
  }
}