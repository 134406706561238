.app__work {
  height: auto;
  background: var(--color-bg-dark);
  color: var(--color-gray1);
  font-family: var(--font-base);
  padding-bottom: 10rem;
  width: 100%;
}

.app__work-title {
  padding-top: 4rem;
  margin-bottom: 4rem;
  color: var(--color-gray2);
  font-size: 1rem;
  font-family: var(--font-base);
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  left: -25%;
}

.app__work-arrow__bs {
  height: 30px;
  width: auto;
  margin-right: 0.45rem;
  color: var(--color-white);
}

.app__work-coming {
  color: var(--color-gray1);
}

.app__work-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.app__work-center {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: fit-content;
  height:auto;
  margin-bottom: 8rem;
}

.app__work-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease;
}

.app__work-overlay:hover {
  opacity: 1;
}

.app__work-img img {
  width: 60%;
  height: auto;
}

@media screen and (min-width: 1900px) {
  .app__work-title {
    font-size: 2rem;
    left: -20%
  }
}

@media screen and (max-width: 1500px) {
  .app__work-img img {
    width: 80%;
    height: auto;
    margin-bottom: 4rem;
  }
}

@media screen and (max-width: 650px) {
  .app__work-img img {
    width: 90%;
    height: auto;
    margin-bottom: 2rem;
  }

  .app__work-title {
    left: -10%;
    margin-bottom: 2rem;
  }

  .app__work {
    padding-bottom: 6rem;
  }
}